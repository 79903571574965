<template>
  <div>
    <page-container :title="breadcrumb" :breadcrumb="breadcrumb">
      <div class="bg-white">
        <div class="main-container" style="margin-top: -15px">
          <div class="title">远程管理</div>
        </div>
      </div>
      <div class="container">
        <div class="table-btns" style="margin-top: 2%">
          <a-button type="primary" size="large" @click="getOrSet" style="margin-left: 80%">
            {{status ?'下发当前参数':'获取最新参数' }}</a-button>
          <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="margin-left: 1%;font-size: 20px"></a-icon>
        </div>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 20px">
          <a-form-model-item label="设备编码" prop="devicenum">
            <a-input v-model="devicenum" placeholder="请输入设备编码"></a-input>
          </a-form-model-item>
          <a-form-model-item label="模块类型" prop="modeltype">
            <a-input v-model="modeltype" placeholder=""></a-input>
          </a-form-model-item>
        </a-form-model>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 1%">
          <a-form-model-item label="远程端口" prop="FRP_port">
            <a-input v-model="iotConfigs.FRP_port" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="远程端口使能" prop="FRP_enable">
            <a-input v-model="iotConfigs.FRP_enable" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="远程服务器地址" prop="FRP_server_addr">
            <a-input v-model="iotConfigs.FRP_server_addr" placeholder=""></a-input>
          </a-form-model-item>
          <!--      <a-form-model-item label="模式参数" prop="Manufacture_mode">-->
          <!--        <a-input v-model="iotConfigs.Manufacture_mode" placeholder=""></a-input>-->
          <!--      </a-form-model-item>-->
          <a-form-model-item label="重启参数" prop="Reboot">
            <a-input v-model="iotConfigs.Reboot" placeholder=""></a-input>
          </a-form-model-item>
        </a-form-model>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:14}" :wrapper-col="{span:10}" style="margin-top: 1%">
          <a-form-model-item label="重启StreamServer服务器的流媒体服务" prop="Reboot">
            <a-button type="primary" size="large" @click="rebootSRS" style="margin-left: 0%;height: 30px">重启服务</a-button>
          </a-form-model-item>
          <a-form-model-item label="重启StreamServer服务器" prop="Reboot">
            <a-button type="primary" size="large" @click="rebootSRSServer" style="margin-left: 0%;height: 30px">重启服务器</a-button>
          </a-form-model-item>
          <a-form-model-item label="重启ESPServer服务器的物联网平台服务" prop="Reboot">
            <a-button type="primary" size="large" @click="rebootPlat" style="margin-left: 0%;height: 30px">重启服务</a-button>
          </a-form-model-item>
          <a-form-model-item label="重启ESPServer服务器" prop="Reboot">
            <a-button type="primary" size="large" @click="rebootESPServer" style="margin-left: 0%;height: 30px">重启服务器</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </page-container>
  </div>
</template>
<script>
import {
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, serverReboot,
  setDeviceparamValueList, shutdownServer,
} from "A/ai";
export default {
  name: "remoteManagement",
  data() {
    return {
      breadcrumb: [
        {
          name: '通用设置',
          path: ''
        },
        {
          name: '远程管理',
          path: ''
        },
      ],
      iotConfigs:{
        PRESSURE_PRECISION:'',
        BASE_PTH:'',
        open_area_pressure_threshold:'',
        T:'',
        PRESSUER_LIST_MAX:'',
        LISTEN_PORT:'',
        SEND_to_mqtt_PORT:'',
        SEND_to_linphone_PORT:'',
        FAULE_CLOSE_DOOR_TIME:'',
        FAULE_OPEN_DOOR_TIME:'',
        TRAPPED_PEOPLE_TIME:'',
        AI_REALSTATE_RECEIVE_TIME:'',
        STANDBY_FLOOR:'',
        detect_other_fault_continuation_times:'',
        T_for_stop_out_open_area:'',
        detect_fault_out_open_area_continuation_times:'',
        detect_out_open_area_continuation_times:'',
        cumulative_person_const:'',
        trapped_person_appear_probability:'',
        floor_height:'',
        out_cancel_car_move_continuation_times:'',
        car_state_continuation_times:'',
        out_car_stop_state_continuation_times:'',
        out_detect_enable_flag:'',
        temp_deviation:'',
        out_threshold_coef:'',
        after_up_no_out_fault_time:'',
        other_fault_coef:'',
        speed_max:'',
        heartbeat_cycle:'',
        all_fault_enable:'',
        alarm_enable:'',
        welcome_audio_enable:'',
        motor_threshold:'',
        motor_continuation_times:'',
        motor_time_threshold:'',
        motor_cancel_time:'',
        loss_floor_pressure_lowest_threshold:'',
        loss_floor_pressure_highest_threshold:'',
        loss_floor_continuation_times:'',
        loss_fault_cancel_threshold:'',
        loss_fault_cancel_continuation_times:'',
        alarm_active_enable:'',
        FRP_port:'',
        FRP_enable:'',
        FRP_server_addr:'',
        Manufacture_mode:'',
        Reboot:'',
      },
      devicemoduletype: '',
      devicenum: '',
      modeltype:'1',
      modelnum: '',
      methodList: [],
      tabKey: '20',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.paramsList = [];
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/iot',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.iotConfigs.PRESSURE_PRECISION=res.item[0].params.body.PRESSURE_PRECISION
          this.iotConfigs.BASE_PTH=res.item[0].params.body.BASE_PTH
          this.iotConfigs.open_area_pressure_threshold=res.item[0].params.body.open_area_pressure_threshold
          this.iotConfigs.T=res.item[0].params.body.T
          this.iotConfigs.PRESSUER_LIST_MAX=res.item[0].params.body.PRESSUER_LIST_MAX
          this.iotConfigs.LISTEN_PORT=res.item[0].params.body.LISTEN_PORT
          this.iotConfigs.SEND_to_mqtt_PORT=res.item[0].params.body.SEND_to_mqtt_PORT
          this.iotConfigs.SEND_to_linphone_PORT=res.item[0].params.body.SEND_to_linphone_PORT
          this.iotConfigs.FAULE_CLOSE_DOOR_TIME=res.item[0].params.body.FAULE_CLOSE_DOOR_TIME
          this.iotConfigs.FAULE_OPEN_DOOR_TIME=res.item[0].params.body.FAULE_OPEN_DOOR_TIME
          this.iotConfigs.TRAPPED_PEOPLE_TIME=res.item[0].params.body.TRAPPED_PEOPLE_TIME
          this.iotConfigs.AI_REALSTATE_RECEIVE_TIME=res.item[0].params.body.AI_REALSTATE_RECEIVE_TIME
          this.iotConfigs.STANDBY_FLOOR=res.item[0].params.body.STANDBY_FLOOR
          this.iotConfigs.detect_other_fault_continuation_times=res.item[0].params.body.detect_other_fault_continuation_times
          this.iotConfigs.T_for_stop_out_open_area=res.item[0].params.body.T_for_stop_out_open_area
          this.iotConfigs.detect_fault_out_open_area_continuation_times=res.item[0].params.body.detect_fault_out_open_area_continuation_times
          this.iotConfigs.detect_out_open_area_continuation_times=res.item[0].params.body.detect_out_open_area_continuation_times
          this.iotConfigs.cumulative_person_const=res.item[0].params.body.cumulative_person_const
          this.iotConfigs.trapped_person_appear_probability=res.item[0].params.body.trapped_person_appear_probability
          this.iotConfigs.floor_height=res.item[0].params.body.floor_height
          this.iotConfigs.out_cancel_car_move_continuation_times=res.item[0].params.body.out_cancel_car_move_continuation_times
          this.iotConfigs.car_state_continuation_times=res.item[0].params.body.car_state_continuation_times
          this.iotConfigs.out_car_stop_state_continuation_times=res.item[0].params.body.out_car_stop_state_continuation_times
          this.iotConfigs.out_detect_enable_flag=res.item[0].params.body.out_detect_enable_flag
          this.iotConfigs.temp_deviation=res.item[0].params.body.temp_deviation
          this.iotConfigs.out_threshold_coef=res.item[0].params.body.out_threshold_coef
          this.iotConfigs.after_up_no_out_fault_time=res.item[0].params.body.after_up_no_out_fault_time
          this.iotConfigs.other_fault_coef=res.item[0].params.body.other_fault_coef
          this.iotConfigs.speed_max=res.item[0].params.body.speed_max
          this.iotConfigs.heartbeat_cycle=res.item[0].params.body.heartbeat_cycle
          this.iotConfigs.all_fault_enable=res.item[0].params.body.all_fault_enable
          this.iotConfigs.alarm_enable=res.item[0].params.body.alarm_enable
          this.iotConfigs.welcome_audio_enable=res.item[0].params.body.welcome_audio_enable
          this.iotConfigs.motor_threshold=res.item[0].params.body.motor_threshold
          this.iotConfigs.motor_continuation_times=res.item[0].params.body.motor_continuation_times
          this.iotConfigs.motor_time_threshold=res.item[0].params.body.motor_time_threshold
          this.iotConfigs.motor_cancel_time=res.item[0].params.body.motor_cancel_time
          this.iotConfigs.loss_floor_pressure_lowest_threshold=res.item[0].params.body.loss_floor_pressure_lowest_threshold
          this.iotConfigs.loss_floor_pressure_highest_threshold=res.item[0].params.body.loss_floor_pressure_highest_threshold
          this.iotConfigs.loss_floor_continuation_times=res.item[0].params.body.loss_floor_continuation_times
          this.iotConfigs.loss_fault_cancel_threshold=res.item[0].params.body.loss_fault_cancel_threshold
          this.iotConfigs.loss_fault_cancel_continuation_times=res.item[0].params.body.loss_fault_cancel_continuation_times
          this.iotConfigs.alarm_active_enable=res.item[0].params.body.alarm_active_enable
          this.iotConfigs.FRP_port=res.item[0].params.body.FRP_port
          this.iotConfigs.FRP_enable=res.item[0].params.body.FRP_enable
          this.iotConfigs.FRP_server_addr=res.item[0].params.body.FRP_server_addr
          this.iotConfigs.Manufacture_mode=res.item[0].params.body.Manufacture_mode
          this.iotConfigs.Reboot=res.item[0].params.body.Reboot
          console.log(res.item[0].params.body)
        }
      }).catch(err => {
        this.hideLoading();
      })
      // }
    },
    setParamsConfig(){
      // let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      // if(selectedMethod) {
      let paramsBody = {
        PRESSURE_PRECISION: this.iotConfigs.PRESSURE_PRECISION,
        BASE_PTH: this.iotConfigs.BASE_PTH,
        open_area_pressure_threshold: this.iotConfigs.open_area_pressure_threshold,
        T: this.iotConfigs.T,
        PRESSUER_LIST_MAX: this.iotConfigs.PRESSUER_LIST_MAX,
        LISTEN_PORT: this.iotConfigs.LISTEN_PORT,
        SEND_to_mqtt_PORT: this.iotConfigs.SEND_to_mqtt_PORT,
        SEND_to_linphone_PORT: this.iotConfigs.SEND_to_linphone_PORT,
        FAULE_CLOSE_DOOR_TIME: this.iotConfigs.FAULE_CLOSE_DOOR_TIME,
        FAULE_OPEN_DOOR_TIME: this.iotConfigs.FAULE_OPEN_DOOR_TIME,
        TRAPPED_PEOPLE_TIME: this.iotConfigs.TRAPPED_PEOPLE_TIME,
        AI_REALSTATE_RECEIVE_TIME: this.iotConfigs.AI_REALSTATE_RECEIVE_TIME,
        STANDBY_FLOOR: this.iotConfigs.STANDBY_FLOOR,
        detect_other_fault_continuation_times: this.iotConfigs.detect_other_fault_continuation_times,
        T_for_stop_out_open_area: this.iotConfigs.T_for_stop_out_open_area,
        detect_fault_out_open_area_continuation_times: this.iotConfigs.detect_fault_out_open_area_continuation_times,
        detect_out_open_area_continuation_times: this.iotConfigs.detect_out_open_area_continuation_times,
        cumulative_person_const: this.iotConfigs.cumulative_person_const,
        trapped_person_appear_probability: this.iotConfigs.trapped_person_appear_probability,
        floor_height: this.iotConfigs.floor_height,
        out_cancel_car_move_continuation_times: this.iotConfigs.out_cancel_car_move_continuation_times,
        car_state_continuation_times: this.iotConfigs.car_state_continuation_times,
        out_car_stop_state_continuation_times: this.iotConfigs.out_car_stop_state_continuation_times,
        out_detect_enable_flag: this.iotConfigs.out_detect_enable_flag,
        temp_deviation: this.iotConfigs.temp_deviation,
        out_threshold_coef: this.iotConfigs.out_threshold_coef,
        after_up_no_out_fault_time: this.iotConfigs.after_up_no_out_fault_time,
        other_fault_coef: this.iotConfigs.other_fault_coef,
        speed_max: this.iotConfigs.speed_max,
        heartbeat_cycle: this.iotConfigs.heartbeat_cycle,
        all_fault_enable: this.iotConfigs.all_fault_enable,
        alarm_enable: this.iotConfigs.alarm_enable,
        welcome_audio_enable: this.iotConfigs.welcome_audio_enable,
        motor_threshold: this.iotConfigs.motor_threshold,
        motor_continuation_times: this.iotConfigs.motor_continuation_times,
        motor_time_threshold: this.iotConfigs.motor_time_threshold,
        motor_cancel_time: this.iotConfigs.motor_cancel_time,
        loss_floor_pressure_lowest_threshold: this.iotConfigs.loss_floor_pressure_lowest_threshold,
        loss_floor_pressure_highest_threshold: this.iotConfigs.loss_floor_pressure_highest_threshold,
        loss_floor_continuation_times: this.iotConfigs.loss_floor_continuation_times,
        loss_fault_cancel_threshold: this.iotConfigs.loss_fault_cancel_threshold,
        loss_fault_cancel_continuation_times: this.iotConfigs.loss_fault_cancel_continuation_times,
        alarm_active_enable: this.iotConfigs.alarm_active_enable,
        FRP_port:this.iotConfigs.FRP_port,
        FRP_enable:this.iotConfigs.FRP_enable,
        FRP_server_addr:this.iotConfigs.FRP_server_addr,
        Manufacture_mode:this.iotConfigs.Manufacture_mode,
        Reboot:this.iotConfigs.Reboot,
      };
      console.log(paramsBody);
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url:'/iot',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
      // }
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
    rebootSRS(){
      let that=this;
      this.$confirm({
        title: '您确定要重启服务/服务器吗?',
        content: '如果您确定需要重启，请单击确认键以完成重启。',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
            let params={
              url:"111.8.58.50:7090"
            }
            serverReboot(params).then(res=>{
              if(res&&res.returncode=='0'){
                that.$message.success("服务即将重启，请耐心等待！")
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
        },
      });
    },
    rebootSRSServer(){
      let that=this;
      this.$confirm({
        title: '您确定要重启服务/服务器吗?',
        content: '如果您确定需要重启，请单击确认键以完成重启。',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
            let params={
              url:"111.8.58.50:7090"
            }
            shutdownServer(params).then(res=>{
              if(res&&res.returncode=='0'){
                that.$message.success("服务器将在三分钟后重启，请耐心等待！")
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
        },
      });
    },
    rebootPlat(){
      let that=this;
      this.$confirm({
        title: '您确定要重启服务/服务器吗?',
        content: '如果您确定需要重启，请单击确认键以完成重启。',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
            let params={
              url:"111.8.58.50:7091"
            }
            serverReboot(params).then(res=>{
              if(res&&res.returncode=='0'){
                that.$message.success("服务即将重启，请耐心等待！")
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
        },
      });
    },
    rebootESPServer(){
      let that=this;
      this.$confirm({
        title: '您确定要重启服务/服务器吗?',
        content: '如果您确定需要重启，请单击确认键以完成重启。',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
            let params={
              url:"111.8.58.50:7091"
            }
            shutdownServer(params).then(res=>{
              if(res&&res.returncode=='0'){
                that.$message.success("服务器将在三分钟后重启，请耐心等待！")
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
        },
      });
    }
  }
}
</script>
<style scoped>
.bg-white {
  background-color: #fff;
}
.container{
  background-color: #fff;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  flex-grow: 1;
  overflow: auto;
  height: 730px;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}
.main-container {
  display: flex;
  padding: 10px;
}

</style>